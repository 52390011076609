.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
  background-color: #282c34;
}

.nav-link {
  text-decoration: none;
  color: white;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #4a4f5a;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a[href="/logout"] {
  color: #d32f2f;
  font-weight: bold;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn, .nav-btn {
  background-color: #282c34;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  cursor: pointer;
}

.logo {
  max-height: 40px;  /* Adjust based on your preference */
  vertical-align: middle;
  margin-right: 10px; /* Some spacing if needed */
}
