body {
    background-color: #282c34;
    color: #fafafa;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

.container {
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    background-color: #1c1e22;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.App-header {
    text-align: center;
    margin-bottom: 20px;
}

.App-header h1 {
    font-size: 2rem;
    color: #61dafb;
}

.controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.controls input, .controls select, .datepicker {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #4a4f5a;
    background-color: #3a3f4a;
    color: #fafafa;
    font-size: 16px;
}

.basic-multi-select {
    max-width: 300px; /* Set a max-width to avoid overlap */
}

/* Styles for react-select */
.select__control {
    background-color: #3a3f4a !important;
    border: 1px solid #4a4f5a !important;
    color: #fafafa !important;
}

.select__menu {
    background-color: #3a3f4a !important;
    color: #fafafa !important;
}

.select__option {
    background-color: #3a3f4a !important;
    color: #fafafa !important;
}

.select__option--is-focused {
    background-color: #575b65 !important;
}

.select__option--is-selected {
    background-color: #4a4f5a !important;
    color: #fafafa !important;
}

.select__multi-value {
    background-color: #4a4f5a !important;
    color: #fafafa !important;
}

.select__multi-value__label {
    color: #fafafa !important;
}

.select__multi-value__remove {
    color: #fafafa !important;
    cursor: pointer;
}

.select__multi-value__remove:hover {
    background-color: #ff6666 !important;
    color: #fafafa !important;
}

.select__placeholder {
    color: #fafafa !important;
}

.select__single-value {
    color: #fafafa !important;
}

.table-container {
    overflow-x: auto;
    margin-top: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    background-color: #3a3f4a;
    border-radius: 10px;
    overflow: hidden;
}

th, td {
    border: 1px solid #4a4f5a;
    padding: 12px;
    text-align: left;
    cursor: pointer;
}

th {
    background-color: #4a4f5a;
    color: #fafafa;
    font-weight: bold;
}

tr:nth-child(even) {
    background-color: #2c313a;
}

tr:hover {
    background-color: #4a4f5a;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 5px;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #4a4f5a;
    background-color: #3a3f4a;
    color: #fafafa;
    font-size: 16px;
    cursor: pointer;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.results-count {
    text-align: center;
    margin-top: 10px;
    font-size: 16px;
    color: #61dafb;
}

select[multiple] {
    height: auto;
}

/* Styles for the modal */
.modal {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: #1c1e22;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 800px;
    color: #fafafa;
    max-height: 80vh;
    overflow-y: auto;
}

.overlay {
    background-color: rgba(0, 0, 0, 0.75);
}

.close-button {
    background: none;
    border: none;
    color: #61dafb;
    cursor: pointer;
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
}

.close-button:hover {
    color: #ffffff;
}

.modal-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.modal-top {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.modal-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.modal-bottom {
    margin-top: 20px;
}

.single-spaced {
    margin: 0;
    line-height: 1.2;
}
