body {
  background-color: #282c34;
  color: #fafafa;
  margin: 0;
  font-family: Arial, sans-serif;
}

.login-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px; /* Space between children */
}

.error-message {
  color: red;
  margin-bottom: 10px;
  text-align: center;
}

input {
  padding: 10px;
  border: 1px solid #4a4f5a;
  border-radius: 4px;
  width: 250px;
  background-color: #3a3f4a;
  color: #ddd;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #61dafb;
  color: #282c34;
  width: 250px;
}

button:hover {
  background-color: #4a4f5a;
}

table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  font-size: .75rem; 
  height: 1.25rem;    
  line-height: 1.25rem; 
}

table, th, td {
  border: 1px solid #4a4f5a;
  padding: 0px;
  color: #ddd;
}

th {
  background-color: #3a3f4a;
}

tr:nth-child(even) {
  background-color: #3a3f4a;
}

tr:nth-child(odd) {
  background-color: #282c34;
}

tr:hover {
  background-color: #4a4f5a;
}

table {
  margin: 0 auto;
  max-width: 90%;
}

.flex-container {
  display: flex;
}

.flex-child {
  flex: 1;
}  

.flex-child:first-child {
  margin-right: 20px;
} 

h3 {
  color: #61dafb;
}